<template>
    <QueueTemplate
        :headers="headers"
        :get-all-queue-elements="queueCourseFieldsDocumentsWS"
        :change-element-state="changeQueueCourseFieldStatusWS"
        :no_block_to_pending="true"
        @send-element-data="receiveElementData"
        :custom_item_slots="['item-id_idioma', 'item-urls']"
        process_key_name="id_lote"
        :force_btn="true"
        :hide_retries="true"
        default_sort_by="id_lote"
    >
        <template #item-id_idioma="{item}">
            <span>{{getNameLanguage(item.id_idioma)}}</span>
        </template>

        <template #item-urls="{item}">
            <v-btn class="btn button-primary" @click="openModalUrls(item)">
                VER URLs
            </v-btn>
        </template>

        <template #custom-title>
            <span class="main-title-page">
                <img :src="require('@/assets/icons/navbar/queue_redirection.svg')" alt="icono de cola redirecciones" width="28" height="28">
                Documentos de campos ficha producto encolados
            </span>
        </template>
        <template #queue-filters="{form_data}">
            <v-col cols="12" lg="6">
                <v-text-field
                    class="text-field-secondary"
                    v-model.trim="form_data.id_lote"
                    label="Buscar por id del lote..."
                    append-inner-icon="mdi-magnify"
                    single-line
                    bg-color="white"
                    color="transparent"
                    hint="Búsquedas exactas"
                    persistent-hint
                />
            </v-col>
            <v-col cols="12" lg="6">
                <v-text-field
                    class="text-field-secondary"
                    v-model.trim="form_data.username"
                    label="Buscar por usuario..."
                    append-inner-icon="mdi-magnify"
                    single-line
                    bg-color="white"
                    color="transparent"
                    hint="Búsquedas exactas"
                    persistent-hint
                />
            </v-col>
            <v-col cols="12" lg="6">
                <v-select
                    v-model="form_data.language_id"
                    class="input-error select-secondary"
                    placeholder="Seleccione un idioma"
                    :items="languages"
                    item-title="nombre"
                    item-value="id_idioma"
                    bg-color="white"
                    hide-details
                    no-data-text="No hay datos disponibles"
                />
            </v-col>
        </template>
        <template #extra-btn-actions="{item_data}">
            <v-list-item v-if="item_data.id_estado === 3 || item_data.id_estado === 4">
                <v-list-item-title @click="downloadSelectedDocument(item_data)"  class="text-center">
                    <span style="cursor: pointer">Descargar Excel</span>
                </v-list-item-title>
            </v-list-item>
        </template>
        <template v-slot:modal-element-log>
            <v-dialog v-model="show_log_modal" persistent>
                <ModalQueueCourseFieldsLogs
                    :selected_element="log_id"
                    :open-close-dialog="openModalLog"
                />
            </v-dialog>
        </template>
    </QueueTemplate>

    <v-dialog v-model="show_urls_modal" persistent>
        <QueueCourseFieldsUrls
            :open-close-dialog="openModalUrls"
            :selected_element="selected_item"
        />
    </v-dialog>

    <v-dialog v-model="open_modal_excel" persistent>
        <BaseModalTemplate
            :title="'Fichero Excel generado'"
            card_extra_classes="modal-width-xs"
            :hide_actions="true"
            :open-close-dialog="changeModalExcel"
            :body_extra_classes="'body-excel-modal'"
        >
            <template #body-modal-content>
                <template v-if="loading_excel">
                    <Loading></Loading>
                </template>
                <v-row v-else>
                    <v-col :cols="variableIsDefined(excel_errors_url) ? 6 : 12" class="text-center">
                        <a
                            class="btn button-primary"
                            type="button"
                            :href="excel_generated_url"
                            download="excel_generado"
                        >
                            Descargar generados
                        </a>
                    </v-col>
                    <v-col v-if="variableIsDefined(excel_errors_url)" cols="6" class="text-center">
                        <a
                            class="btn button-primary"
                            type="button" :href="excel_errors_url"
                            download="excel_erroneos"
                        >
                            Descargar erroneos
                        </a>
                    </v-col>
                </v-row>
            </template>
        </BaseModalTemplate>
    </v-dialog>
</template>

<script>
import QueueTemplate from "@/components/Generic/QueueTemplate.vue";
import ModalLogRedirection from "@/components/Redirections/ModalLogRedirection.vue";
import {ref} from "vue";
import {changeQueueCourseFieldStatusWS, getCourseFieldsDocumentWS, queueCourseFieldsDocumentsWS} from "@/utils/courses";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {getNameLanguage} from "@/utils/portal";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import QueueCourseFieldsUrls from "@/components/Course/QueueCourseFieldsUrls.vue";
import {useStore} from "vuex";
import ModalQueueCourseFieldsLogs from "@/components/Course/ModalQueueCourseFieldsLogs.vue";
import Loading from "@/components/Generic/Loading.vue";

export default {
    name: "QueueCourseFieldsView.vue",
    methods: {
        changeQueueCourseFieldStatusWS,
        getNameLanguage,
        variableIsDefined,
        queueCourseFieldsDocumentsWS,
    },
    components: {
        Loading,
        ModalQueueCourseFieldsLogs, QueueCourseFieldsUrls, BaseModalTemplate, ModalLogRedirection, QueueTemplate},
    setup(){
        const store = useStore();
        let languages = ref(Array.from(new Map(store.getters.getStoredLanguages.map(obj => [JSON.stringify(obj.nombre), obj])).values()))

        let headers = ref([
            {text: 'ID', value: 'id_lote', sortable: true},
            {text: 'IDIOMA', value: 'id_idioma'},
            {text: 'CREADO', value: 'created_at', sortable: true},
            {text: 'ACTUALIZADO', value: 'updated_at', sortable: true},
            {text: 'NOMBRE FICHERO', value: 'name_excel'},
            {text: 'ESTADO', value: 'id_estado'},
            {text: 'PESO', value: 'peso', sortable: true},
            {text: 'USUARIO', value: 'username'},
            {text: 'URLs AFECTADAS', value: 'urls'},
            {text: 'ACCIONES', value: 'btn_actions'},
        ])

        let show_log_modal = ref(false)
        let show_urls_modal = ref(false)

        let log_id = ref()
        let selected_item = ref()

        let open_modal_excel = ref(false)
        let excel_generated_url = ref("")
        let excel_errors_url = ref("")
        let loading_excel = ref(false)

        const openModalLog = () => {
            show_log_modal.value = !show_log_modal.value
        }

        const openModalUrls = (item = "") => {
            if (variableIsDefined(item)){
                selected_item.value = item.id_lote
            }
            show_urls_modal.value = !show_urls_modal.value
        }

        const receiveElementData = (element_data) => {
            log_id.value = element_data.id_lote
            openModalLog()
        }

        const checkBlob = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = function (event) {
                    try {
                        const result = JSON.parse(event.target.result);

                        if (result.result === false) {
                            resolve({ isError: true});
                        } else {
                            resolve({ isError: false });
                        }
                    } catch (e) {
                        resolve({ isError: false });
                    }
                };

                reader.onerror = function () {
                    reject('Error al leer el blob.');
                };

                reader.readAsText(file);
            });
        }


        const changeModalExcel = () => {
            open_modal_excel.value = !open_modal_excel.value
        }

        const downloadSelectedDocument = async (selected_item) => {
            changeModalExcel()
            loading_excel.value = true
            excel_generated_url.value = ""
            excel_errors_url.value = ""

            //LLamadas a WS
            let response_error_mode = await getCourseFieldsDocumentWS(selected_item.id_lote, true)
            let response_no_error_mode = await getCourseFieldsDocumentWS(selected_item.id_lote, false)

            //Ficheros
            const error_blob = new Blob([response_error_mode.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const no_error_blob = new Blob([response_no_error_mode.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            let verification_blob_error = await checkBlob(error_blob);
            let verification_blob_no_error = await checkBlob(no_error_blob);

            if (!verification_blob_no_error.isError && !verification_blob_error.isError){
                if (selected_item.id_estado === 4) {
                    excel_errors_url.value = window.URL.createObjectURL(error_blob)
                }

                excel_generated_url.value = window.URL.createObjectURL(no_error_blob)

            }else{
                showNotification('error', 'Problemas al recuperar el excel')
            }

            loading_excel.value = false
        }

        return{
            receiveElementData,
            openModalLog,
            openModalUrls,
            downloadSelectedDocument,
            changeModalExcel,
            show_urls_modal,
            headers,
            show_log_modal,
            log_id,
            selected_item,
            languages,


            open_modal_excel,
            excel_errors_url,
            excel_generated_url,
            loading_excel,
        }
    }
}
</script>

<style lang="scss">
    .body-excel-modal{
        display: flex;
        align-items: center;
        min-height: 15vh !important;
    }
</style>