<template>
    <template v-if="data.tipo_url === 'contenido_seo'">
        <v-btn class="btn button-bg-blue mr-3 my-2"
               @click="generateAutoContent()"
               :loading="loading_writesonic"> Generar contenido con Writesonic
        </v-btn>
        <p class="error-writesonic">{{ error_writesonic }}</p>
        <p class="success-writesonic">{{ msg_writesonic }}</p>
    </template>

    <div v-if="!loadi" id="scrollable-content" data-bs-spy="scroll" data-bs-target="#list-timeline">
        <form v-if="!loadi" class="editor" @submit.prevent="">
            <v-row class="text-end justify-content-end">
                <v-col cols="12" md="4" class="text-end">
                    <v-switch
                        color="blue"
                        v-model="autosaved"
                        label="Autoguardado"
                    ></v-switch>
                </v-col>
            </v-row>
            <div class="card container-link" id="commercial-data">
                <div class="card-header">
                    <p class="group-title">
                        Datos comerciales
                    </p>
                </div>

                <div class="card-body custom-card-body">
                    <template v-if="form_data.tipo_url ==='ficha_producto'">
                        <v-text-field
                            counter
                            persistent-counter
                            label="Título comercial"
                            v-model="form_data.titulo_comercial" variant="underlined"
                            class="text-field-primary field-with-label"
                        ></v-text-field>
                        <v-text-field
                            counter
                            persistent-counter
                            label="Subtítulo comercial"
                            v-model="form_data.subtitulo_comercial" variant="underlined"
                            class="text-field-primary field-with-label"
                        ></v-text-field>
                    </template>
                    <v-text-field
                        counter
                        persistent-counter
                        label="Título Mostrar (Título Curso / Título Blog)"
                        v-model="form_data.title" variant="underlined"
                        class="text-field-primary field-with-label"
                    ></v-text-field>
                    <template v-if="form_data.tipo_url ==='contenido_seo'">
                        <label> Fecha útil (fecha por la que se ordenan los contenidos en la web)</label>
                        <InputCalendar
                            :filled_date="form_data.usable_date"
                            ref="date_usable"
                        ></InputCalendar>
                    </template>
                </div>
            </div>
            <div class="card container-link" id="keywords-data">
                <div class="card-header">
                    <p class="group-title">
                        Datos Keywords
                    </p>
                </div>
                <div class="card-body custom-card-body">
                    <v-text-field
                        counter
                        persistent-counter
                        label="Keyword objetivo"
                        v-model="form_data.keyword_objetivo" variant="underlined"
                        class="text-field-primary field-with-label"
                    ></v-text-field>
                    <template v-if="form_data.tipo_url ==='ficha_producto'">
                        <v-text-field
                            counter
                            persistent-counter
                            label="Keyword relacionada"
                            v-model="form_data.kw_relacionada" variant="underlined"
                            class="text-field-primary field-with-label"
                        ></v-text-field>
                        <v-text-field
                            counter
                            persistent-counter
                            label="Keyword relacionada 2"
                            v-model="form_data.kw_relacionada_2" variant="underlined"
                            class="text-field-primary field-with-label"
                        ></v-text-field>
                        <v-text-field
                            counter
                            persistent-counter
                            label="Keyword relacionada 3"
                            v-model="form_data.kw_relacionada_3" variant="underlined"
                            class="text-field-primary field-with-label"
                        ></v-text-field>
                        <v-text-field
                            counter
                            persistent-counter
                            label="Keyword relacionada 4"
                            v-model="form_data.kw_relacionada_4" variant="underlined"
                            class="text-field-primary field-with-label"
                        ></v-text-field>
                    </template>
                </div>
            </div>
            <div class="card container-link" id="additional-info">
                <div class="card-header">
                    <p class="group-title">
                        Información complementaria
                    </p>
                </div>

                <div class="card-body custom-card-body">
                    <v-text-field
                        counter
                        persistent-counter
                        label="H1"
                        v-model="form_data.h1" variant="underlined"
                        class="text-field-primary field-with-label"
                    ></v-text-field>
                    <v-text-field
                        maxlength="60"
                        counter
                        persistent-counter
                        label="Meta título"
                        v-model="form_data.titulo_serp" variant="underlined"
                        class="text-field-primary field-with-label"
                    ></v-text-field>
                    <v-text-field
                        maxlength="150"
                        counter
                        persistent-counter
                        label="Meta descripción"
                        v-model="form_data.meta_description" variant="underlined"
                        class="text-field-primary field-with-label"
                    ></v-text-field>
                    <div class="field-group" v-if="received_fields.descripcion_seo || form_data.tipo_url === 'contenido_seo'">
                        <label for="editor-content" class="form-label">Descripción SEO</label>
                        <TinyEditor
                            :model="form_data.content"
                            :id="'editor-content'"
                            :height="600"
                            @customChange="form_data.content = $event"
                        >
                        </TinyEditor>
                        <v-row class="text-center" v-if="form_data.tipo_url === 'contenido_seo'">
                            <v-col cols="12" sm="6">
                                <v-btn
                                    class="btn button-bg-white mt-5"
                                    @click="chooseGPTWS('Contenido SEO HTML')"
                                >
                                    Crear contenido SEO
                                </v-btn>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-btn
                                    class="btn button-bg-white mt-5"
                                    @click="chooseGPTWS('Aumento contenido HTML')"
                                >
                                    Ampliar contenido SEO
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="field-group">
                        <v-row class="text-start">
                            <v-col cols="12">
                                <v-btn
                                    class="btn button-bg-blue"
                                    @click="checkForbiddenWords"
                                    :loading="loading_check"
                                >
                                    COMPROBAR PALABRAS NEGATIVAS
                                </v-btn>
                            </v-col>
                            <template v-if="show_negative_words_check">
                                <v-col cols="12" class="negative-words-text" v-if="find">
                                    Palabras negativas encontradas (<b>{{ negative_words_found.length }}</b>):
                                </v-col>
                                <v-col cols="12" v-if="find">
                                    <div class="word-list personalize-scroll">
                                        <ul class="negative-list">
                                            <li class="word-list-element" v-for="word in negative_words_found">
                                                {{ word }}
                                            </li>
                                        </ul>
                                    </div>
                                </v-col>
                                <v-col cols="12" class="negative-words-text" v-else>
                                    No se han encontrado palabras negativas en el texto.
                                </v-col>
                            </template>
                        </v-row>
                    </div>

                    <div class="field-group" v-if="received_fields.cita_seo && form_data.tipo_url === 'ficha_producto'">
                        <label for="cita_seo" class="form-label">Cita SEO</label>
                        <TinyEditor
                            :model="form_data.cita_seo"
                            :id="'editor-cita_seo'"
                            :height="800"
                            @customChange="form_data.cita_seo = $event"
                        >
                        </TinyEditor>
                    </div>

                    <div class="field-group" v-if="form_data.tipo_url === 'ficha_producto'">
                        <label for="editor-frase_destacada" class="form-label">Frase destacada</label>
                        <TinyEditor
                            :model="form_data.frase_destacada"
                            :id="'editor-frase_destacada'"
                            :height="200"
                            @customChange="form_data.frase_destacada = $event"
                        >
                        </TinyEditor>
                    </div>

                    <div class="field-group" v-if="form_data.tipo_url === 'contenido_seo'">
                        <label for="editor-frase_destacada" class="form-label">Referencias bibliograficas</label>
                        <TinyEditor
                            :model="form_data.referencias_bibliograficas"
                            :id="'editor-referencias_bibliograficas'"
                            :height="400"
                            @customChange="form_data.referencias_bibliograficas = $event"
                        >
                        </TinyEditor>
                    </div>

                </div>
            </div>
            <div class="card container-link" id="media-content-data">
                <div class="card-header">
                    <p class="group-title">
                        Datos multimedia
                    </p>
                </div>
                <div class="card-body custom-card-body">
                    <v-text-field
                        counter
                        persistent-counter
                        label="URL imagen destacada"
                        v-model="form_data.imagen_cdn" variant="underlined"
                        class="text-field-primary field-with-label"
                    ></v-text-field>

                    <template v-if="form_data.tipo_url === 'ficha_producto'">
                        <v-text-field
                            counter
                            persistent-counter
                            label="ID video director académico"
                            v-model="form_data.id_video" variant="underlined"
                            class="text-field-primary field-with-label"
                        ></v-text-field>
                        <v-text-field
                            counter
                            persistent-counter
                            label="ID Video review"
                            v-model="form_data.id_video_review" variant="underlined"
                            class="text-field-primary field-with-label"
                        ></v-text-field>
                    </template>
                    <template v-else>
                        <v-text-field
                            counter
                            persistent-counter
                            label="URL curso destacado (sin el dominio. Ej: formador-formadores)"
                            v-model="form_data.curso_destacado" variant="underlined"
                            class="text-field-primary field-with-label"
                        ></v-text-field>
                    </template>

                    <v-text-field
                        counter
                        persistent-counter
                        label="Url vídeo destacado cabecera escritorio (en móvil se mostrará el mismo fichero seguido de -movil. Ejemplo: educacion-movil.mp4)"
                        v-model="form_data.video" variant="underlined"
                        class="text-field-primary field-with-label"
                    ></v-text-field>

                    <template v-if="form_data.tipo_url === 'contenido_seo'">
                        <v-textarea
                            label="Descripción vídeo-producto"
                            v-model.trim="form_data.descripcion_video_producto"
                            variant="underlined"
                            class="input-error text-area-secondary personalize-scroll"
                            rows="3"
                            counter
                            persistent-counter
                        ></v-textarea>
                    </template>
                </div>
            </div>
            <div class="card container-link" id="upload-img">
                <div class="card-header">
                    <p class="group-title">
                        Subida de imágenes (webp)
                    </p>
                </div>
                <div class="card-body custom-card-body">
                    <FileInputDragDrop></FileInputDragDrop>
                </div>
            </div>
            <div class="card container-link" id="categorization">
                <div class="card-header">
                    <p class="group-title">
                        Categorización
                    </p>
                </div>
                <div class="card-body custom-card-body">
                    <template
                        v-if="form_data.tipo_url === 'contenido_seo' && !disabled_basic_categorization.includes(id_portal)">
                        <p class="categorization-type">* Categorización básica</p>
                        <BasicCategorization
                            :form_data="form_data"
                            ref="basic_reference_categorization"
                        ></BasicCategorization>
                    </template>

                    <p class="categorization-type mt-2">* Categorización en el portal</p>
                    <Categorization
                        :form_data="form_data"
                        :seo_content="seo_content"
                        ref="reference_categorization"
                        @categorizationCheck="handleCategorizationCheck"
                    ></Categorization>
                </div>
            </div>

            <div class="card container-link" id="review" v-if="form_data.tipo_url === 'ficha_producto'">
                <div class="card-header">
                    <p class="group-title">
                        Revisiones
                    </p>
                </div>

                <div class="card-body custom-card-body">
                    <div class="checkbox-list">
                        <v-checkbox
                            label="SEO Revisado"
                            v-model="form_data.seo_revisado"
                            :true-value="1"
                            :false-value="0"
                            color="blue"
                            hide-details
                        ></v-checkbox>
                        <v-checkbox
                            label="Backlink y Redes Sociales"
                            v-model="form_data.backlink_revisado"
                            :true-value="1"
                            :false-value="0"
                            color="blue"
                            hide-details
                        ></v-checkbox>
                        <v-checkbox
                            label="Enlaces internos y CTR"
                            v-model="form_data.ctr_revisado"
                            :true-value="1"
                            :false-value="0"
                            color="blue"
                            hide-details
                        ></v-checkbox>
                        <v-checkbox
                            label="Páginas seo relacionadas"
                            v-model="form_data.seo_relacionadas"
                            :true-value="1"
                            :false-value="0"
                            color="blue"
                            hide-details
                        ></v-checkbox>
                        <v-checkbox
                            label="SEO actualizado"
                            v-model="form_data.seo_actualizado"
                            :true-value="1"
                            :false-value="0"
                            color="blue"
                            hide-details
                        ></v-checkbox>

                        <v-checkbox
                            label="Mantenimiento SEO"
                            v-model="form_data.mantenimiento_seo"
                            :true-value="1"
                            :false-value="0"
                            color="blue"
                            hide-details
                        ></v-checkbox>

                        <v-checkbox
                            label="Imagen revisada"
                            v-model="form_data.imagen_revisada"
                            :true-value="1"
                            :false-value="0"
                            color="blue"
                            hide-details
                        ></v-checkbox>


                        <v-checkbox
                            label="Claustro docente revisado"
                            v-model="form_data.docentes_revisados"
                            :true-value="1"
                            :false-value="0"
                            color="blue"
                            hide-details
                        ></v-checkbox>

                        <v-checkbox
                            label="Opiniones revisadas"
                            v-model="form_data.opiniones_revisadas"
                            :true-value="1"
                            :false-value="0"
                            color="blue"
                            hide-details
                        ></v-checkbox>

                    </div>
                </div>
            </div>
            <div class="card container-link" id="options-review">
                <div class="card-header">
                    <p class="group-title">
                        Opciones de visualización
                    </p>
                </div>

                <div class="card-body custom-card-body">
                    <div class="checkbox-list">
                        <v-checkbox
                            label="Quitar cursos relacionados agrupados"
                            v-model="form_data.excepcion_cursos_agrupados"
                            :true-value="1"
                            :false-value="0"
                            color="blue"
                            hide-details
                        ></v-checkbox>

                        <template v-if="form_data.tipo_url === 'contenido_seo'">
                            <v-checkbox
                                label="Listo para mostrar en blog"
                                v-model="form_data.completado"
                                :true-value="1"
                                :false-value="0"
                                color="blue"
                                hide-details
                            ></v-checkbox>

                            <v-checkbox
                                label="Mostrar en sección noticias"
                                v-model="form_data.seccion_noticias"
                                :true-value="1"
                                :false-value="0"
                                color="blue"
                                hide-details
                            ></v-checkbox>

                            <v-checkbox
                                label="Mostrar en sección Faculty And Research"
                                v-model="form_data.seccion_faculty_research"
                                :true-value="1"
                                :false-value="0"
                                color="blue"
                                hide-details
                            ></v-checkbox>
                        </template>
                    </div>
                </div>
            </div>
            <div class="card container-link" id="actions">
                <div class="card-header">
                    <p class="group-title">
                        Acciones
                    </p>
                </div>

                <div class="card-body custom-card-body">
                    <v-row class="action-btn-list">
                        <Actions
                            :draft="draft"
                            :data_url="data.url"
                            :form_data="form_data"
                            :draft-type="'blog'"
                        ></Actions>
                    </v-row>
                </div>
            </div>
            <div class="card container-link" id="author">
                <div class="card-header">
                    <p class="group-title">
                        Autor:
                    </p>
                </div>

                <div class="card-body custom-card-body">
                    <v-autocomplete v-if="editor_admin"
                                    label="Selecciona autor"
                                    v-model="form_data.author"
                                    :items="copywriters"
                                    variant="underlined"
                                    class="select-primary"
                                    hide-details
                    ></v-autocomplete>

                    <v-autocomplete v-else readonly
                                    label="Autor (no editable)"
                                    v-model="form_data.author"
                                    :items="copywriters"
                                    variant="underlined"
                                    class="select-primary"
                                    hide-details
                    ></v-autocomplete>

                    <p id="draf_author" v-if="form_data.draft_author">
                        Última modificación realizada por: <span>{{ form_data.draft_author }}</span>
                    </p>
                </div>
            </div>


            <v-row class="text-center">
                <v-col cols="12">
                    <p class="error-msg" v-if="general_error_msg">Se han encontrado errores. Por favor, revise
                        todos los
                        campos
                        <v-icon icon="mdi-arrow-up-circle-outline" @click="navigateToTop()"></v-icon>
                    </p>
                </v-col>
            </v-row>
            <v-row class="align-center">
                <v-col cols="12" md="6" class="text-center text-md-right">
                    <v-btn v-if="editor_admin"
                           @click="prePublishURL()"
                           class="btn button-primary btn-xl"
                           :disabled="loading_small">
                        Guardar y publicar
                    </v-btn>
                </v-col>
                <v-col cols="12" md="6" class="text-center text-md-left">
                    <v-btn @click="sendForm(true)"
                           class="btn button-primary btn-xl  ms-md-3"
                           :disabled="loading_small">
                        Guardar
                        borrador
                    </v-btn>
                </v-col>
            </v-row>
        </form>
    </div>

    <v-dialog v-model="display_dialog_seo">
        <FormChatGPT
            :show-dialog="displayDialog"
            :selection="gpt_selection"
            :updateFunction="changeSEO"
            :html_doc="html_doc"
            :keywords="url_keywords"
        >
        </FormChatGPT>
    </v-dialog>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import {computed, onBeforeMount, onBeforeUnmount, onMounted, onUnmounted, ref, watch} from "vue";
import {
    deleteTagsHTML, formatDate, formatOnlyDate,
    htmlDecode as auxHtmlDecode, objectsHasSameInfo,
    showNotification,
    variableIsDefined
} from "@/utils/aux_functions";
import * as Yup from "yup";

import {
    generateContentWriteSonicWS,
    getArticleTypesWS,
    saveUrlDataWS,
} from "@/utils/editor_course_blog";
import {useRoute, useRouter} from "vue-router";
import {userHaveAllowedRol} from "@/utils/auth";
import {getFieldsToPortalWS} from "@/utils/editor_course_blog";
import FormChatGPT from "@/components/Forms/FormChatGPT.vue";
import ModalUpdateDeleteRedirection from "@/components/Redirections/ModalUpdateDeleteRedirection.vue";
import ModalLinkBoxes from "@/components/LinkBoxes/ModalLinkBoxes.vue";
import TinyEditor from "@/components/Editor/TinyEditor.vue";
import FileInputDragDrop from "@/components/Generic/FileInputDragDrop.vue";
import {checkNegativeWordsWS} from "@/utils/negative_words";
import Categorization from "@/components/Editor/Categorization.vue";
import ModalURLCheck from "@/components/SEOEdition/ModalURLCheck.vue";
import GenerateImage from "@/components/Editor/Buttons/GenerateImage.vue";
import BrokenLinks from "@/components/Editor/Buttons/BrokenLinks.vue";
import Redirects from "@/components/Editor/Buttons/Redirects.vue";
import DeletePage from "@/components/Editor/Buttons/DeletePage.vue";
import LinkBoxesEditor from "@/components/Editor/Buttons/LinkBoxesEditor.vue";
import Actions from "@/components/Editor/Buttons/Actions.vue";
import {getEditorCopywritersWS} from "@/utils/copywriters";
import BasicCategorization from "@/components/Editor/BasicCategorization.vue";
import {useStore} from "vuex";
import {fireSweetAlert} from "@/utils/sweetalert";
import InputCalendar from "@/components/Generic/InputCalendar.vue";


export default {
    name: "FormSeo",
    methods: {},
    components: {
        InputCalendar,
        BasicCategorization,
        Actions,
        LinkBoxesEditor,
        DeletePage,
        Redirects,
        BrokenLinks,
        GenerateImage,
        Categorization,
        FileInputDragDrop,
        TinyEditor, ModalLinkBoxes, ModalURLCheck, ModalUpdateDeleteRedirection, FormChatGPT, Editor,
    },
    props: {
        data: Object,
        draft: Boolean,
        updateForm: Function,
    },

    setup(props) {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();

        let form_data = ref();
        let form_error = ref({});
        let general_error_msg = ref(false);
        let seo_content = ref([]);
        let copywriters = ref([]);
        let control_position = ref();
        let root = ref();
        let loading_small = ref(false);
        let editor_admin = ref(false);
        let received_data;
        let display_dialog_seo = ref(false);
        let gpt_selection = ref('');
        let html_doc = ref('');
        let url_keywords = ref('');

        let display_dialog_check_urls = ref(false);
        let areas_web = ref([]);
        let id_portal = computed(() => store.getters.getPortalID).value
        /*Variables para busqueda de palabras negativas*/
        let negative_words_found = ref([]);
        let find = ref(false);
        let show_negative_words_check = ref(false);
        let loading_check = ref(false);
        const disabled_basic_categorization = [65, 243, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262, 263, 264, 265, 266, 267, 268, 269, 270, 271, 272, 306, 324, 325, 326, 327,308]
        let reference_categorization = ref(null)
        let basic_reference_categorization = ref(null)

        let auto_save;
        let autosaved = ref(true)

        let error_writesonic = ref();
        let msg_writesonic = ref();
        let loading_writesonic = ref();

        let date_usable = ref(null);

        let received_fields = ref([]);

        let checkDataFormSeo = ref([]);


        root.value = $('#root')[0];


        let loadi = ref(true)

        onMounted(async () => {
            received_data = _.cloneDeep(props.data);
            form_data.value = props.data;

            form_data.value.finalizacion_descuento = new Date(form_data.value.finalizacion_descuento * 1000);
            await setArticleTypeSelect();
            editor_admin.value = userHaveAllowedRol("PERMISO_ADMIN_EDITOR_SEO");
            await getEditorCopywriters();
            let results = await getFieldsToPortalWS();
            if(results){
                received_fields.value = results.data.fields;
            }
            control_position.value = new IntersectionObserver(onElementObserved, {
                root: null,
                threshold: 0.22,
            });

            $('#root')[0].querySelectorAll('.card .container-link[id]').forEach(section => {
                control_position.value.observe(section)
            })

            auto_save = setInterval(
                () => {
                sendForm(true, false, 1)
            }, 300000 /*5min*/, true, true)

            loadScript('https://cdn.educa.net/tinymce/js/tinymce/tinymce.min.js')
                .then(() => {
                    loadi.value = false
                    // Tu lógica aquí, después de que el script se haya cargado
                })
                .catch((error) => {
                    console.error('Error cargando el script:', error);
                });
        })

        function handleCategorizationCheck(checkData) {
            checkDataFormSeo.value = checkData;
        }

        function loadScript(src){
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = src;
                script.onload = () => resolve();
                script.onerror = () => reject(new Error(`Error cargando el script: ${src}`));
                document.head.appendChild(script);
            });
        }


        onUnmounted(() => {
            clearInterval(auto_save)
        })

        watch(autosaved, () => {
            if (!autosaved.value) {
                clearInterval(auto_save)
            } else {
                auto_save = setInterval(
                    () => {
                        sendForm(true, false, 1)
                    }, 300000 /*5min*/, true, true)
            }
        })

        onBeforeMount(async () => {
            // received_data = _.cloneDeep(props.data);
            // form_data.value = props.data;
            // form_data.value.finalizacion_descuento = new Date(form_data.value.finalizacion_descuento * 1000);
            // await setArticleTypeSelect();
            // editor_admin.value = userHaveAllowedRol("PERMISO_ADMIN_EDITOR_SEO");
            // await getEditorCopywriters()
        })

        /*******************************
         *
         * Creación contenido seo con ChatGPT
         *
         * *****************************/

        function displayDialog() {
            display_dialog_seo.value = !display_dialog_seo.value;
        }

        function changeSEO(new_text) {
            form_data.value.content = new_text;
            html_doc.value = new_text;
        }

        function chooseGPTWS(service) {
            gpt_selection.value = service;

            if (variableIsDefined(form_data.value.keyword_objetivo)) {
                url_keywords.value = form_data.value.keyword_objetivo + ',';
            }
            if (form_data.value.tipo_url === 'ficha_producto') {
                url_keywords.value += form_data.value.kw_relacionada + ','
                    + form_data.value.kw_relacionada_2 + ','
                    + form_data.value.kw_relacionada_3 + ','
                    + form_data.value.kw_relacionada_4 + ','
            }
            url_keywords.value = url_keywords.value.replaceAll(',null', '');
            url_keywords.value = url_keywords.value.replaceAll(',undefined', '');

            html_doc.value = form_data.value.content;

            displayDialog();
        }

        /************************************************************/


        function onElementObserved(entries) {
            entries.forEach(({target, isIntersecting}) => {
                const id = target.getAttribute('id')
                const el = $('a.timeline-item[href="#' + id + '"]');
                if (isIntersecting) {
                    $(el[0]).parent().addClass('active')
                } else {
                    $(el[0]).parent().removeClass('active')
                }
            })
        }

        function htmlDecode(input) {
            return auxHtmlDecode(input)
        }

        const setArticleTypeSelect = async () => {
            let response = await getArticleTypesWS();
            let articles_types = [];
            if (response.result) {
                Object.entries(response.data).forEach(tipo_articulo => {
                    articles_types.push({'tid': parseInt(tipo_articulo[0]), 'nombre': tipo_articulo[1]})
                })
                seo_content.value = articles_types;
            }
        }

        const getEditorCopywriters = async () => {
            copywriters.value = [];
            let response = await getEditorCopywritersWS()
            if (response['result']) {
                response.data.forEach(copy => {
                    copywriters.value.push(copy.username)
                })
            } else {
                showNotification('error', response['errors'])
            }
        }

        const validateForm = async () => {

            form_error.value = [];
            general_error_msg.value = false;

            let schemaFormShape = {};

            if(checkDataFormSeo.value.area){
                schemaFormShape.areaTID = Yup.number()
                    .typeError('El campo Area no puede estar vacío')
                    .required('El campo Area es obligatorio')
                    .notOneOf([0], 'El campo Area no puede estar "Sin Area"');
            }

            if(checkDataFormSeo.value.facultad){
                schemaFormShape.categoryTID = Yup.number()
                    .typeError('El campo Facultad no puede estar vacío')
                    .required('El campo Facultad es obligatorio')
                    .notOneOf([0], 'El campo Facultad no puede estar "Sin Faculdad"');
            }

            if(checkDataFormSeo.value.escuela) {
                schemaFormShape.subcategoryTID = Yup.number()
                    .typeError('El campo Escuela no puede estar vacío')
                    .required('El campo Escuela es obligatorio')
                    .notOneOf([0], 'El campo Escuela no puede estar "Sin Escuela"');
            }

            const schemaForm = Yup.object().shape(schemaFormShape);

            try {
                await schemaForm.validate(form_data.value, {abortEarly: false});
            } catch (error) {
                error.inner.forEach((error) => {
                    if (error.path === 'areaTID') {
                        form_error.value.areaTID = error.message;
                    } else if (error.path === 'categoryTID') {
                        form_error.value.categoryTID = error.message;
                    } else if (error.path === 'subcategoryTID') {
                        form_error.value.subcategoryTID = error.message;
                    }
                });
                general_error_msg.value = true;
            }

        }

        async function prePublishURL() {
            let forbidden = await checkForbiddenWords();
            if (!forbidden) {
                await sendForm();
            } else {
                await fireSweetAlert('¡Atención!', async () => await sendForm(), 'Se han encontrado palabras negativas en el contenido. ¿Publicar de todas formas?')
            }
        }

        async function sendForm(draft = false, auto = false, is_autosave = 0) {
            let changed = false;

            loading_small.value = true;

            if (form_data.value.tipo_url === 'contenido_seo'){
                form_data.value['usable_date'] = formatOnlyDate(date_usable.value.input_date, true,'aaaa-mm-dd')
            }
            await reference_categorization.value.setFinalCategoriesValues()
            if (!disabled_basic_categorization.includes(id_portal) && form_data.value.tipo_url === 'contenido_seo') {
                await basic_reference_categorization.value.setBasicFinalCategoriesValues()
            }

            await validateForm();

            if (general_error_msg.value) {
                if (form_error.value.areaTID) {
                    showNotification('error', form_error.value.areaTID);
                } else if (form_error.value.categoryTID) {
                    showNotification('error', form_error.value.categoryTID);
                } else if (form_error.value.subcategoryTID) {
                    showNotification('error', form_error.value.subcategoryTID);
                }
                loading_small.value = false;
                return;
            }

            if (hasSameData(draft) === false) {
                form_data.value['url'] = route.query.url;
                form_data.value['is_autoguardado'] = is_autosave
                await validateForm()
                if (form_error.value.length === 0) {
                    let response = await saveUrlDataWS(form_data.value, draft, 'editor-seo');
                    try {
                        if (typeof response === "boolean" && !response) {
                            showNotification('error', 'Se ha producido un error en el servidor')
                        } else {
                            if (response.result) {
                                if (response.data.success) {
                                    if (draft) {
                                        if (!auto) {
                                            showNotification('success', 'Borrador guardado correctamente')
                                            changed = true
                                        } else {
                                            received_data = _.cloneDeep(form_data.value)
                                        }
                                    } else {
                                        showNotification('success', 'Se ha publicado correctamente el curso.')
                                        changed = true
                                    }
                                    if (changed && !auto && !draft) {
                                        await props.updateForm()
                                    }
                                } else {
                                    showNotification('info', response.data.message)
                                }
                            } else {
                                showNotification('error', response.errors)
                                if (response.errors[0] && variableIsDefined(response.errors[0].negative_words)) {
                                    negative_words_found.value = response.errors[0].negative_words;
                                    find.value = true;
                                    show_negative_words_check.value = true;
                                }
                            }
                        }
                    } catch (e) {
                        showNotification('error', 'Se ha producido un error en el servidor')
                    }
                }
            } else {
                if (auto === false) {
                    showNotification('info', 'No se han detectado cambios que guardar')
                }
            }
            loading_small.value = false;
        }

        const hasSameData = (draft) => {
            if (draft) {
                let cloned_form_data = _.cloneDeep(form_data.value)
                //Se transforman tipados a lo recibido
                if (variableIsDefined(cloned_form_data.area)) {
                    cloned_form_data.areaTID = cloned_form_data.areaTID.toString();
                }
                if (variableIsDefined(received_data.areaTID)) {
                    received_data.areaTID = received_data.areaTID.toString();
                }

                if (variableIsDefined(cloned_form_data.categoryTID)) {
                    cloned_form_data.categoryTID = cloned_form_data.categoryTID.toString();
                }
                if (variableIsDefined(received_data.categoryTID)) {
                    received_data.categoryTID = received_data.categoryTID.toString();
                }

                if (variableIsDefined(cloned_form_data.subcategoryTID)) {
                    cloned_form_data.subcategoryTID = cloned_form_data.subcategoryTID.toString();
                }
                if (variableIsDefined(received_data.subcategoryTID)) {
                    received_data.subcategoryTID = received_data.subcategoryTID.toString();
                }

                delete cloned_form_data.finalizacion_descuento
                delete received_data.finalizacion_descuento
                return objectsHasSameInfo(received_data, cloned_form_data);
            }
            return false;

        }

        const navigateToTop = () => {
            window.scrollTo(0, 0);
        }

        //Datos que se reemplazan: descripcion_seo, h1, meta_descripcion.
        async function generateAutoContent() {
            error_writesonic.value = "";
            msg_writesonic.value = "";
            loading_writesonic.value = true;
            if (variableIsDefined(form_data.value.keyword_objetivo)) {
                let data = {
                    'url': form_data.value.url,
                    'keyword': form_data.value.keyword_objetivo
                }
                if (!variableIsDefined(form_data.value.h1) && !variableIsDefined(form_data.value.meta_description) && !variableIsDefined(form_data.value.content)) {
                    await generateContentWritesonic(data)
                } else {
                    await fireSweetAlert('Reemplazar', async () => await generateContentWritesonic(data), 'Se han encontrado datos en el campo h1, meta descripcion, metatítulo. ¿Quieres reemplazar la información?')
                }
            } else {
                error_writesonic.value = 'El campo keyword objetivo debe estar relleno para la generación de blogs'
            }
            loading_writesonic.value = false;

        }

        async function generateContentWritesonic(data) {

            let response = await generateContentWriteSonicWS(data);
            if (response['result']) {
                form_data.value.h1 = response['data'].h1
                form_data.value.meta_description = response['data'].description
                form_data.value.content = response['data'].blog
                msg_writesonic.value = 'Campos reemplazados con éxito'
            } else {
                showNotification('error', response['errors'])
            }
        }


        async function checkForbiddenWords() {
            find.value = false;

            loading_check.value = true;
            let content = form_data.value.content;
            if (variableIsDefined(content)) {
                const response = await checkNegativeWordsWS(deleteTagsHTML(content));
                show_negative_words_check.value = true;

                if (response['result']) {
                    if (response['data']['find']) {
                        negative_words_found.value = response['data']['negative_words'];
                        find.value = true;
                    }
                } else {
                    showNotification('error', response['errors'])
                }
            }

            loading_check.value = false;


            return find.value
        }


        return {
            htmlDecode,
            sendForm,
            navigateToTop,
            displayDialog,
            changeSEO,
            chooseGPTWS,
            checkForbiddenWords,
            prePublishURL,
            generateAutoContent,
            handleCategorizationCheck,

            disabled_basic_categorization,
            id_portal,
            find,
            negative_words_found,
            loading_check,
            show_negative_words_check,
            loading_small,
            areas_web,
            copywriters,
            seo_content,
            general_error_msg,
            form_data,
            form_error,
            editor_admin,
            display_dialog_seo,
            gpt_selection,
            display_dialog_check_urls,
            html_doc,
            url_keywords,
            reference_categorization,
            basic_reference_categorization,
            error_writesonic,
            msg_writesonic,
            loading_writesonic,
            date_usable,
            received_fields,
            autosaved,
            loadi,
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/components/form-seo-course.scss";
</style>
